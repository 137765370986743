// Variables
// --------------------------

$fa-font-path:                "../webfonts" !default;
$fa-font-size-base:           16px !default;
$fa-css-prefix:               fa !default;
$fa-version:                  "5.0.7" !default;
$fa-border-color:             #eee !default;
$fa-inverse:                  #fff !default;
$fa-li-width:                 2em !default;

// Convenience function used to set content property
@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

$fa-var-500px: \f26e;
$fa-var-accessible-icon: \f368;
$fa-var-accusoft: \f369;
$fa-var-address-book: \f2b9;
$fa-var-address-card: \f2bb;
$fa-var-adjust: \f042;
$fa-var-adn: \f170;
$fa-var-adversal: \f36a;
$fa-var-affiliatetheme: \f36b;
$fa-var-alarm-clock: \f34e;
$fa-var-algolia: \f36c;
$fa-var-align-center: \f037;
$fa-var-align-justify: \f039;
$fa-var-align-left: \f036;
$fa-var-align-right: \f038;
$fa-var-allergies: \f461;
$fa-var-amazon: \f270;
$fa-var-amazon-pay: \f42c;
$fa-var-ambulance: \f0f9;
$fa-var-american-sign-language-interpreting: \f2a3;
$fa-var-amilia: \f36d;
$fa-var-anchor: \f13d;
$fa-var-android: \f17b;
$fa-var-angellist: \f209;
$fa-var-angle-double-down: \f103;
$fa-var-angle-double-left: \f100;
$fa-var-angle-double-right: \f101;
$fa-var-angle-double-up: \f102;
$fa-var-angle-down: \f107;
$fa-var-angle-left: \f104;
$fa-var-angle-right: \f105;
$fa-var-angle-up: \f106;
$fa-var-angrycreative: \f36e;
$fa-var-angular: \f420;
$fa-var-app-store: \f36f;
$fa-var-app-store-ios: \f370;
$fa-var-apper: \f371;
$fa-var-apple: \f179;
$fa-var-apple-pay: \f415;
$fa-var-archive: \f187;
$fa-var-arrow-alt-circle-down: \f358;
$fa-var-arrow-alt-circle-left: \f359;
$fa-var-arrow-alt-circle-right: \f35a;
$fa-var-arrow-alt-circle-up: \f35b;
$fa-var-arrow-alt-down: \f354;
$fa-var-arrow-alt-from-bottom: \f346;
$fa-var-arrow-alt-from-left: \f347;
$fa-var-arrow-alt-from-right: \f348;
$fa-var-arrow-alt-from-top: \f349;
$fa-var-arrow-alt-left: \f355;
$fa-var-arrow-alt-right: \f356;
$fa-var-arrow-alt-square-down: \f350;
$fa-var-arrow-alt-square-left: \f351;
$fa-var-arrow-alt-square-right: \f352;
$fa-var-arrow-alt-square-up: \f353;
$fa-var-arrow-alt-to-bottom: \f34a;
$fa-var-arrow-alt-to-left: \f34b;
$fa-var-arrow-alt-to-right: \f34c;
$fa-var-arrow-alt-to-top: \f34d;
$fa-var-arrow-alt-up: \f357;
$fa-var-arrow-circle-down: \f0ab;
$fa-var-arrow-circle-left: \f0a8;
$fa-var-arrow-circle-right: \f0a9;
$fa-var-arrow-circle-up: \f0aa;
$fa-var-arrow-down: \f063;
$fa-var-arrow-from-bottom: \f342;
$fa-var-arrow-from-left: \f343;
$fa-var-arrow-from-right: \f344;
$fa-var-arrow-from-top: \f345;
$fa-var-arrow-left: \f060;
$fa-var-arrow-right: \f061;
$fa-var-arrow-square-down: \f339;
$fa-var-arrow-square-left: \f33a;
$fa-var-arrow-square-right: \f33b;
$fa-var-arrow-square-up: \f33c;
$fa-var-arrow-to-bottom: \f33d;
$fa-var-arrow-to-left: \f33e;
$fa-var-arrow-to-right: \f340;
$fa-var-arrow-to-top: \f341;
$fa-var-arrow-up: \f062;
$fa-var-arrows: \f047;
$fa-var-arrows-alt: \f0b2;
$fa-var-arrows-alt-h: \f337;
$fa-var-arrows-alt-v: \f338;
$fa-var-arrows-h: \f07e;
$fa-var-arrows-v: \f07d;
$fa-var-assistive-listening-systems: \f2a2;
$fa-var-asterisk: \f069;
$fa-var-asymmetrik: \f372;
$fa-var-at: \f1fa;
$fa-var-audible: \f373;
$fa-var-audio-description: \f29e;
$fa-var-autoprefixer: \f41c;
$fa-var-avianex: \f374;
$fa-var-aviato: \f421;
$fa-var-aws: \f375;
$fa-var-backward: \f04a;
$fa-var-badge: \f335;
$fa-var-badge-check: \f336;
$fa-var-balance-scale: \f24e;
$fa-var-ban: \f05e;
$fa-var-band-aid: \f462;
$fa-var-bandcamp: \f2d5;
$fa-var-barcode: \f02a;
$fa-var-barcode-alt: \f463;
$fa-var-barcode-read: \f464;
$fa-var-barcode-scan: \f465;
$fa-var-bars: \f0c9;
$fa-var-baseball: \f432;
$fa-var-baseball-ball: \f433;
$fa-var-basketball-ball: \f434;
$fa-var-basketball-hoop: \f435;
$fa-var-bath: \f2cd;
$fa-var-battery-bolt: \f376;
$fa-var-battery-empty: \f244;
$fa-var-battery-full: \f240;
$fa-var-battery-half: \f242;
$fa-var-battery-quarter: \f243;
$fa-var-battery-slash: \f377;
$fa-var-battery-three-quarters: \f241;
$fa-var-bed: \f236;
$fa-var-beer: \f0fc;
$fa-var-behance: \f1b4;
$fa-var-behance-square: \f1b5;
$fa-var-bell: \f0f3;
$fa-var-bell-slash: \f1f6;
$fa-var-bicycle: \f206;
$fa-var-bimobject: \f378;
$fa-var-binoculars: \f1e5;
$fa-var-birthday-cake: \f1fd;
$fa-var-bitbucket: \f171;
$fa-var-bitcoin: \f379;
$fa-var-bity: \f37a;
$fa-var-black-tie: \f27e;
$fa-var-blackberry: \f37b;
$fa-var-blind: \f29d;
$fa-var-blogger: \f37c;
$fa-var-blogger-b: \f37d;
$fa-var-bluetooth: \f293;
$fa-var-bluetooth-b: \f294;
$fa-var-bold: \f032;
$fa-var-bolt: \f0e7;
$fa-var-bomb: \f1e2;
$fa-var-book: \f02d;
$fa-var-bookmark: \f02e;
$fa-var-bowling-ball: \f436;
$fa-var-bowling-pins: \f437;
$fa-var-box: \f466;
$fa-var-box-check: \f467;
$fa-var-boxes: \f468;
$fa-var-boxing-glove: \f438;
$fa-var-braille: \f2a1;
$fa-var-briefcase: \f0b1;
$fa-var-briefcase-medical: \f469;
$fa-var-browser: \f37e;
$fa-var-btc: \f15a;
$fa-var-bug: \f188;
$fa-var-building: \f1ad;
$fa-var-bullhorn: \f0a1;
$fa-var-bullseye: \f140;
$fa-var-burn: \f46a;
$fa-var-buromobelexperte: \f37f;
$fa-var-bus: \f207;
$fa-var-buysellads: \f20d;
$fa-var-calculator: \f1ec;
$fa-var-calendar: \f133;
$fa-var-calendar-alt: \f073;
$fa-var-calendar-check: \f274;
$fa-var-calendar-edit: \f333;
$fa-var-calendar-exclamation: \f334;
$fa-var-calendar-minus: \f272;
$fa-var-calendar-plus: \f271;
$fa-var-calendar-times: \f273;
$fa-var-camera: \f030;
$fa-var-camera-alt: \f332;
$fa-var-camera-retro: \f083;
$fa-var-capsules: \f46b;
$fa-var-car: \f1b9;
$fa-var-caret-circle-down: \f32d;
$fa-var-caret-circle-left: \f32e;
$fa-var-caret-circle-right: \f330;
$fa-var-caret-circle-up: \f331;
$fa-var-caret-down: \f0d7;
$fa-var-caret-left: \f0d9;
$fa-var-caret-right: \f0da;
$fa-var-caret-square-down: \f150;
$fa-var-caret-square-left: \f191;
$fa-var-caret-square-right: \f152;
$fa-var-caret-square-up: \f151;
$fa-var-caret-up: \f0d8;
$fa-var-cart-arrow-down: \f218;
$fa-var-cart-plus: \f217;
$fa-var-cc-amazon-pay: \f42d;
$fa-var-cc-amex: \f1f3;
$fa-var-cc-apple-pay: \f416;
$fa-var-cc-diners-club: \f24c;
$fa-var-cc-discover: \f1f2;
$fa-var-cc-jcb: \f24b;
$fa-var-cc-mastercard: \f1f1;
$fa-var-cc-paypal: \f1f4;
$fa-var-cc-stripe: \f1f5;
$fa-var-cc-visa: \f1f0;
$fa-var-centercode: \f380;
$fa-var-certificate: \f0a3;
$fa-var-chart-area: \f1fe;
$fa-var-chart-bar: \f080;
$fa-var-chart-line: \f201;
$fa-var-chart-pie: \f200;
$fa-var-check: \f00c;
$fa-var-check-circle: \f058;
$fa-var-check-square: \f14a;
$fa-var-chess: \f439;
$fa-var-chess-bishop: \f43a;
$fa-var-chess-bishop-alt: \f43b;
$fa-var-chess-board: \f43c;
$fa-var-chess-clock: \f43d;
$fa-var-chess-clock-alt: \f43e;
$fa-var-chess-king: \f43f;
$fa-var-chess-king-alt: \f440;
$fa-var-chess-knight: \f441;
$fa-var-chess-knight-alt: \f442;
$fa-var-chess-pawn: \f443;
$fa-var-chess-pawn-alt: \f444;
$fa-var-chess-queen: \f445;
$fa-var-chess-queen-alt: \f446;
$fa-var-chess-rook: \f447;
$fa-var-chess-rook-alt: \f448;
$fa-var-chevron-circle-down: \f13a;
$fa-var-chevron-circle-left: \f137;
$fa-var-chevron-circle-right: \f138;
$fa-var-chevron-circle-up: \f139;
$fa-var-chevron-double-down: \f322;
$fa-var-chevron-double-left: \f323;
$fa-var-chevron-double-right: \f324;
$fa-var-chevron-double-up: \f325;
$fa-var-chevron-down: \f078;
$fa-var-chevron-left: \f053;
$fa-var-chevron-right: \f054;
$fa-var-chevron-square-down: \f329;
$fa-var-chevron-square-left: \f32a;
$fa-var-chevron-square-right: \f32b;
$fa-var-chevron-square-up: \f32c;
$fa-var-chevron-up: \f077;
$fa-var-child: \f1ae;
$fa-var-chrome: \f268;
$fa-var-circle: \f111;
$fa-var-circle-notch: \f1ce;
$fa-var-clipboard: \f328;
$fa-var-clipboard-check: \f46c;
$fa-var-clipboard-list: \f46d;
$fa-var-clock: \f017;
$fa-var-clone: \f24d;
$fa-var-closed-captioning: \f20a;
$fa-var-cloud: \f0c2;
$fa-var-cloud-download: \f0ed;
$fa-var-cloud-download-alt: \f381;
$fa-var-cloud-upload: \f0ee;
$fa-var-cloud-upload-alt: \f382;
$fa-var-cloudscale: \f383;
$fa-var-cloudsmith: \f384;
$fa-var-cloudversify: \f385;
$fa-var-club: \f327;
$fa-var-code: \f121;
$fa-var-code-branch: \f126;
$fa-var-code-commit: \f386;
$fa-var-code-merge: \f387;
$fa-var-codepen: \f1cb;
$fa-var-codiepie: \f284;
$fa-var-coffee: \f0f4;
$fa-var-cog: \f013;
$fa-var-cogs: \f085;
$fa-var-columns: \f0db;
$fa-var-comment: \f075;
$fa-var-comment-alt: \f27a;
$fa-var-comments: \f086;
$fa-var-compass: \f14e;
$fa-var-compress: \f066;
$fa-var-compress-alt: \f422;
$fa-var-compress-wide: \f326;
$fa-var-connectdevelop: \f20e;
$fa-var-contao: \f26d;
$fa-var-conveyor-belt: \f46e;
$fa-var-conveyor-belt-alt: \f46f;
$fa-var-copy: \f0c5;
$fa-var-copyright: \f1f9;
$fa-var-cpanel: \f388;
$fa-var-creative-commons: \f25e;
$fa-var-credit-card: \f09d;
$fa-var-credit-card-blank: \f389;
$fa-var-credit-card-front: \f38a;
$fa-var-cricket: \f449;
$fa-var-crop: \f125;
$fa-var-crosshairs: \f05b;
$fa-var-css3: \f13c;
$fa-var-css3-alt: \f38b;
$fa-var-cube: \f1b2;
$fa-var-cubes: \f1b3;
$fa-var-curling: \f44a;
$fa-var-cut: \f0c4;
$fa-var-cuttlefish: \f38c;
$fa-var-d-and-d: \f38d;
$fa-var-dashcube: \f210;
$fa-var-database: \f1c0;
$fa-var-deaf: \f2a4;
$fa-var-delicious: \f1a5;
$fa-var-deploydog: \f38e;
$fa-var-deskpro: \f38f;
$fa-var-desktop: \f108;
$fa-var-desktop-alt: \f390;
$fa-var-deviantart: \f1bd;
$fa-var-diagnoses: \f470;
$fa-var-diamond: \f219;
$fa-var-digg: \f1a6;
$fa-var-digital-ocean: \f391;
$fa-var-discord: \f392;
$fa-var-discourse: \f393;
$fa-var-dna: \f471;
$fa-var-dochub: \f394;
$fa-var-docker: \f395;
$fa-var-dollar-sign: \f155;
$fa-var-dolly: \f472;
$fa-var-dolly-empty: \f473;
$fa-var-dolly-flatbed: \f474;
$fa-var-dolly-flatbed-alt: \f475;
$fa-var-dolly-flatbed-empty: \f476;
$fa-var-dot-circle: \f192;
$fa-var-download: \f019;
$fa-var-draft2digital: \f396;
$fa-var-dribbble: \f17d;
$fa-var-dribbble-square: \f397;
$fa-var-dropbox: \f16b;
$fa-var-drupal: \f1a9;
$fa-var-dumbbell: \f44b;
$fa-var-dyalog: \f399;
$fa-var-earlybirds: \f39a;
$fa-var-edge: \f282;
$fa-var-edit: \f044;
$fa-var-eject: \f052;
$fa-var-elementor: \f430;
$fa-var-ellipsis-h: \f141;
$fa-var-ellipsis-h-alt: \f39b;
$fa-var-ellipsis-v: \f142;
$fa-var-ellipsis-v-alt: \f39c;
$fa-var-ember: \f423;
$fa-var-empire: \f1d1;
$fa-var-envelope: \f0e0;
$fa-var-envelope-open: \f2b6;
$fa-var-envelope-square: \f199;
$fa-var-envira: \f299;
$fa-var-eraser: \f12d;
$fa-var-erlang: \f39d;
$fa-var-ethereum: \f42e;
$fa-var-etsy: \f2d7;
$fa-var-euro-sign: \f153;
$fa-var-exchange: \f0ec;
$fa-var-exchange-alt: \f362;
$fa-var-exclamation: \f12a;
$fa-var-exclamation-circle: \f06a;
$fa-var-exclamation-square: \f321;
$fa-var-exclamation-triangle: \f071;
$fa-var-expand: \f065;
$fa-var-expand-alt: \f424;
$fa-var-expand-arrows: \f31d;
$fa-var-expand-arrows-alt: \f31e;
$fa-var-expand-wide: \f320;
$fa-var-expeditedssl: \f23e;
$fa-var-external-link: \f08e;
$fa-var-external-link-alt: \f35d;
$fa-var-external-link-square: \f14c;
$fa-var-external-link-square-alt: \f360;
$fa-var-eye: \f06e;
$fa-var-eye-dropper: \f1fb;
$fa-var-eye-slash: \f070;
$fa-var-facebook: \f09a;
$fa-var-facebook-f: \f39e;
$fa-var-facebook-messenger: \f39f;
$fa-var-facebook-square: \f082;
$fa-var-fast-backward: \f049;
$fa-var-fast-forward: \f050;
$fa-var-fax: \f1ac;
$fa-var-female: \f182;
$fa-var-field-hockey: \f44c;
$fa-var-fighter-jet: \f0fb;
$fa-var-file: \f15b;
$fa-var-file-alt: \f15c;
$fa-var-file-archive: \f1c6;
$fa-var-file-audio: \f1c7;
$fa-var-file-check: \f316;
$fa-var-file-code: \f1c9;
$fa-var-file-edit: \f31c;
$fa-var-file-excel: \f1c3;
$fa-var-file-exclamation: \f31a;
$fa-var-file-image: \f1c5;
$fa-var-file-medical: \f477;
$fa-var-file-medical-alt: \f478;
$fa-var-file-minus: \f318;
$fa-var-file-pdf: \f1c1;
$fa-var-file-plus: \f319;
$fa-var-file-powerpoint: \f1c4;
$fa-var-file-times: \f317;
$fa-var-file-video: \f1c8;
$fa-var-file-word: \f1c2;
$fa-var-film: \f008;
$fa-var-film-alt: \f3a0;
$fa-var-filter: \f0b0;
$fa-var-fire: \f06d;
$fa-var-fire-extinguisher: \f134;
$fa-var-firefox: \f269;
$fa-var-first-aid: \f479;
$fa-var-first-order: \f2b0;
$fa-var-firstdraft: \f3a1;
$fa-var-flag: \f024;
$fa-var-flag-checkered: \f11e;
$fa-var-flask: \f0c3;
$fa-var-flickr: \f16e;
$fa-var-flipboard: \f44d;
$fa-var-fly: \f417;
$fa-var-folder: \f07b;
$fa-var-folder-open: \f07c;
$fa-var-font: \f031;
$fa-var-font-awesome: \f2b4;
$fa-var-font-awesome-alt: \f35c;
$fa-var-font-awesome-flag: \f425;
$fa-var-fonticons: \f280;
$fa-var-fonticons-fi: \f3a2;
$fa-var-football-ball: \f44e;
$fa-var-football-helmet: \f44f;
$fa-var-forklift: \f47a;
$fa-var-fort-awesome: \f286;
$fa-var-fort-awesome-alt: \f3a3;
$fa-var-forumbee: \f211;
$fa-var-forward: \f04e;
$fa-var-foursquare: \f180;
$fa-var-free-code-camp: \f2c5;
$fa-var-freebsd: \f3a4;
$fa-var-frown: \f119;
$fa-var-futbol: \f1e3;
$fa-var-gamepad: \f11b;
$fa-var-gavel: \f0e3;
$fa-var-gem: \f3a5;
$fa-var-genderless: \f22d;
$fa-var-get-pocket: \f265;
$fa-var-gg: \f260;
$fa-var-gg-circle: \f261;
$fa-var-gift: \f06b;
$fa-var-git: \f1d3;
$fa-var-git-square: \f1d2;
$fa-var-github: \f09b;
$fa-var-github-alt: \f113;
$fa-var-github-square: \f092;
$fa-var-gitkraken: \f3a6;
$fa-var-gitlab: \f296;
$fa-var-gitter: \f426;
$fa-var-glass-martini: \f000;
$fa-var-glide: \f2a5;
$fa-var-glide-g: \f2a6;
$fa-var-globe: \f0ac;
$fa-var-gofore: \f3a7;
$fa-var-golf-ball: \f450;
$fa-var-golf-club: \f451;
$fa-var-goodreads: \f3a8;
$fa-var-goodreads-g: \f3a9;
$fa-var-google: \f1a0;
$fa-var-google-drive: \f3aa;
$fa-var-google-play: \f3ab;
$fa-var-google-plus: \f2b3;
$fa-var-google-plus-g: \f0d5;
$fa-var-google-plus-square: \f0d4;
$fa-var-google-wallet: \f1ee;
$fa-var-graduation-cap: \f19d;
$fa-var-gratipay: \f184;
$fa-var-grav: \f2d6;
$fa-var-gripfire: \f3ac;
$fa-var-grunt: \f3ad;
$fa-var-gulp: \f3ae;
$fa-var-h-square: \f0fd;
$fa-var-h1: \f313;
$fa-var-h2: \f314;
$fa-var-h3: \f315;
$fa-var-hacker-news: \f1d4;
$fa-var-hacker-news-square: \f3af;
$fa-var-hand-holding-box: \f47b;
$fa-var-hand-lizard: \f258;
$fa-var-hand-paper: \f256;
$fa-var-hand-peace: \f25b;
$fa-var-hand-point-down: \f0a7;
$fa-var-hand-point-left: \f0a5;
$fa-var-hand-point-right: \f0a4;
$fa-var-hand-point-up: \f0a6;
$fa-var-hand-pointer: \f25a;
$fa-var-hand-receiving: \f47c;
$fa-var-hand-rock: \f255;
$fa-var-hand-scissors: \f257;
$fa-var-hand-spock: \f259;
$fa-var-handshake: \f2b5;
$fa-var-hashtag: \f292;
$fa-var-hdd: \f0a0;
$fa-var-heading: \f1dc;
$fa-var-headphones: \f025;
$fa-var-heart: \f004;
$fa-var-heartbeat: \f21e;
$fa-var-hexagon: \f312;
$fa-var-hips: \f452;
$fa-var-hire-a-helper: \f3b0;
$fa-var-history: \f1da;
$fa-var-hockey-puck: \f453;
$fa-var-hockey-sticks: \f454;
$fa-var-home: \f015;
$fa-var-hooli: \f427;
$fa-var-hospital: \f0f8;
$fa-var-hospital-alt: \f47d;
$fa-var-hospital-symbol: \f47e;
$fa-var-hotjar: \f3b1;
$fa-var-hourglass: \f254;
$fa-var-hourglass-end: \f253;
$fa-var-hourglass-half: \f252;
$fa-var-hourglass-start: \f251;
$fa-var-houzz: \f27c;
$fa-var-html5: \f13b;
$fa-var-hubspot: \f3b2;
$fa-var-i-cursor: \f246;
$fa-var-id-badge: \f2c1;
$fa-var-id-card: \f2c2;
$fa-var-id-card-alt: \f47f;
$fa-var-image: \f03e;
$fa-var-images: \f302;
$fa-var-imdb: \f2d8;
$fa-var-inbox: \f01c;
$fa-var-inbox-in: \f310;
$fa-var-inbox-out: \f311;
$fa-var-indent: \f03c;
$fa-var-industry: \f275;
$fa-var-industry-alt: \f3b3;
$fa-var-info: \f129;
$fa-var-info-circle: \f05a;
$fa-var-info-square: \f30f;
$fa-var-instagram: \f16d;
$fa-var-internet-explorer: \f26b;
$fa-var-inventory: \f480;
$fa-var-ioxhost: \f208;
$fa-var-italic: \f033;
$fa-var-itunes: \f3b4;
$fa-var-itunes-note: \f3b5;
$fa-var-jack-o-lantern: \f30e;
$fa-var-jenkins: \f3b6;
$fa-var-joget: \f3b7;
$fa-var-joomla: \f1aa;
$fa-var-js: \f3b8;
$fa-var-js-square: \f3b9;
$fa-var-jsfiddle: \f1cc;
$fa-var-key: \f084;
$fa-var-keyboard: \f11c;
$fa-var-keycdn: \f3ba;
$fa-var-kickstarter: \f3bb;
$fa-var-kickstarter-k: \f3bc;
$fa-var-korvue: \f42f;
$fa-var-language: \f1ab;
$fa-var-laptop: \f109;
$fa-var-laravel: \f3bd;
$fa-var-lastfm: \f202;
$fa-var-lastfm-square: \f203;
$fa-var-leaf: \f06c;
$fa-var-leanpub: \f212;
$fa-var-lemon: \f094;
$fa-var-less: \f41d;
$fa-var-level-down: \f149;
$fa-var-level-down-alt: \f3be;
$fa-var-level-up: \f148;
$fa-var-level-up-alt: \f3bf;
$fa-var-life-ring: \f1cd;
$fa-var-lightbulb: \f0eb;
$fa-var-line: \f3c0;
$fa-var-link: \f0c1;
$fa-var-linkedin: \f08c;
$fa-var-linkedin-in: \f0e1;
$fa-var-linode: \f2b8;
$fa-var-linux: \f17c;
$fa-var-lira-sign: \f195;
$fa-var-list: \f03a;
$fa-var-list-alt: \f022;
$fa-var-list-ol: \f0cb;
$fa-var-list-ul: \f0ca;
$fa-var-location-arrow: \f124;
$fa-var-lock: \f023;
$fa-var-lock-alt: \f30d;
$fa-var-lock-open: \f3c1;
$fa-var-lock-open-alt: \f3c2;
$fa-var-long-arrow-alt-down: \f309;
$fa-var-long-arrow-alt-left: \f30a;
$fa-var-long-arrow-alt-right: \f30b;
$fa-var-long-arrow-alt-up: \f30c;
$fa-var-long-arrow-down: \f175;
$fa-var-long-arrow-left: \f177;
$fa-var-long-arrow-right: \f178;
$fa-var-long-arrow-up: \f176;
$fa-var-low-vision: \f2a8;
$fa-var-luchador: \f455;
$fa-var-lyft: \f3c3;
$fa-var-magento: \f3c4;
$fa-var-magic: \f0d0;
$fa-var-magnet: \f076;
$fa-var-male: \f183;
$fa-var-map: \f279;
$fa-var-map-marker: \f041;
$fa-var-map-marker-alt: \f3c5;
$fa-var-map-pin: \f276;
$fa-var-map-signs: \f277;
$fa-var-mars: \f222;
$fa-var-mars-double: \f227;
$fa-var-mars-stroke: \f229;
$fa-var-mars-stroke-h: \f22b;
$fa-var-mars-stroke-v: \f22a;
$fa-var-maxcdn: \f136;
$fa-var-medapps: \f3c6;
$fa-var-medium: \f23a;
$fa-var-medium-m: \f3c7;
$fa-var-medkit: \f0fa;
$fa-var-medrt: \f3c8;
$fa-var-meetup: \f2e0;
$fa-var-meh: \f11a;
$fa-var-mercury: \f223;
$fa-var-microchip: \f2db;
$fa-var-microphone: \f130;
$fa-var-microphone-alt: \f3c9;
$fa-var-microphone-slash: \f131;
$fa-var-microsoft: \f3ca;
$fa-var-minus: \f068;
$fa-var-minus-circle: \f056;
$fa-var-minus-hexagon: \f307;
$fa-var-minus-octagon: \f308;
$fa-var-minus-square: \f146;
$fa-var-mix: \f3cb;
$fa-var-mixcloud: \f289;
$fa-var-mizuni: \f3cc;
$fa-var-mobile: \f10b;
$fa-var-mobile-alt: \f3cd;
$fa-var-mobile-android: \f3ce;
$fa-var-mobile-android-alt: \f3cf;
$fa-var-modx: \f285;
$fa-var-monero: \f3d0;
$fa-var-money-bill: \f0d6;
$fa-var-money-bill-alt: \f3d1;
$fa-var-moon: \f186;
$fa-var-motorcycle: \f21c;
$fa-var-mouse-pointer: \f245;
$fa-var-music: \f001;
$fa-var-napster: \f3d2;
$fa-var-neuter: \f22c;
$fa-var-newspaper: \f1ea;
$fa-var-nintendo-switch: \f418;
$fa-var-node: \f419;
$fa-var-node-js: \f3d3;
$fa-var-notes-medical: \f481;
$fa-var-npm: \f3d4;
$fa-var-ns8: \f3d5;
$fa-var-nutritionix: \f3d6;
$fa-var-object-group: \f247;
$fa-var-object-ungroup: \f248;
$fa-var-octagon: \f306;
$fa-var-odnoklassniki: \f263;
$fa-var-odnoklassniki-square: \f264;
$fa-var-opencart: \f23d;
$fa-var-openid: \f19b;
$fa-var-opera: \f26a;
$fa-var-optin-monster: \f23c;
$fa-var-osi: \f41a;
$fa-var-outdent: \f03b;
$fa-var-page4: \f3d7;
$fa-var-pagelines: \f18c;
$fa-var-paint-brush: \f1fc;
$fa-var-palfed: \f3d8;
$fa-var-pallet: \f482;
$fa-var-pallet-alt: \f483;
$fa-var-paper-plane: \f1d8;
$fa-var-paperclip: \f0c6;
$fa-var-paragraph: \f1dd;
$fa-var-paste: \f0ea;
$fa-var-patreon: \f3d9;
$fa-var-pause: \f04c;
$fa-var-pause-circle: \f28b;
$fa-var-paw: \f1b0;
$fa-var-paypal: \f1ed;
$fa-var-pen: \f304;
$fa-var-pen-alt: \f305;
$fa-var-pen-square: \f14b;
$fa-var-pencil: \f040;
$fa-var-pencil-alt: \f303;
$fa-var-pennant: \f456;
$fa-var-percent: \f295;
$fa-var-periscope: \f3da;
$fa-var-phabricator: \f3db;
$fa-var-phoenix-framework: \f3dc;
$fa-var-phone: \f095;
$fa-var-phone-slash: \f3dd;
$fa-var-phone-square: \f098;
$fa-var-phone-volume: \f2a0;
$fa-var-php: \f457;
$fa-var-pied-piper: \f2ae;
$fa-var-pied-piper-alt: \f1a8;
$fa-var-pied-piper-pp: \f1a7;
$fa-var-pills: \f484;
$fa-var-pinterest: \f0d2;
$fa-var-pinterest-p: \f231;
$fa-var-pinterest-square: \f0d3;
$fa-var-plane: \f072;
$fa-var-plane-alt: \f3de;
$fa-var-play: \f04b;
$fa-var-play-circle: \f144;
$fa-var-playstation: \f3df;
$fa-var-plug: \f1e6;
$fa-var-plus: \f067;
$fa-var-plus-circle: \f055;
$fa-var-plus-hexagon: \f300;
$fa-var-plus-octagon: \f301;
$fa-var-plus-square: \f0fe;
$fa-var-podcast: \f2ce;
$fa-var-poo: \f2fe;
$fa-var-portrait: \f3e0;
$fa-var-pound-sign: \f154;
$fa-var-power-off: \f011;
$fa-var-prescription-bottle: \f485;
$fa-var-prescription-bottle-alt: \f486;
$fa-var-print: \f02f;
$fa-var-procedures: \f487;
$fa-var-product-hunt: \f288;
$fa-var-pushed: \f3e1;
$fa-var-puzzle-piece: \f12e;
$fa-var-python: \f3e2;
$fa-var-qq: \f1d6;
$fa-var-qrcode: \f029;
$fa-var-question: \f128;
$fa-var-question-circle: \f059;
$fa-var-question-square: \f2fd;
$fa-var-quidditch: \f458;
$fa-var-quinscape: \f459;
$fa-var-quora: \f2c4;
$fa-var-quote-left: \f10d;
$fa-var-quote-right: \f10e;
$fa-var-racquet: \f45a;
$fa-var-random: \f074;
$fa-var-ravelry: \f2d9;
$fa-var-react: \f41b;
$fa-var-rebel: \f1d0;
$fa-var-rectangle-landscape: \f2fa;
$fa-var-rectangle-portrait: \f2fb;
$fa-var-rectangle-wide: \f2fc;
$fa-var-recycle: \f1b8;
$fa-var-red-river: \f3e3;
$fa-var-reddit: \f1a1;
$fa-var-reddit-alien: \f281;
$fa-var-reddit-square: \f1a2;
$fa-var-redo: \f01e;
$fa-var-redo-alt: \f2f9;
$fa-var-registered: \f25d;
$fa-var-rendact: \f3e4;
$fa-var-renren: \f18b;
$fa-var-repeat: \f363;
$fa-var-repeat-1: \f365;
$fa-var-repeat-1-alt: \f366;
$fa-var-repeat-alt: \f364;
$fa-var-reply: \f3e5;
$fa-var-reply-all: \f122;
$fa-var-replyd: \f3e6;
$fa-var-resolving: \f3e7;
$fa-var-retweet: \f079;
$fa-var-retweet-alt: \f361;
$fa-var-road: \f018;
$fa-var-rocket: \f135;
$fa-var-rocketchat: \f3e8;
$fa-var-rockrms: \f3e9;
$fa-var-rss: \f09e;
$fa-var-rss-square: \f143;
$fa-var-ruble-sign: \f158;
$fa-var-rupee-sign: \f156;
$fa-var-safari: \f267;
$fa-var-sass: \f41e;
$fa-var-save: \f0c7;
$fa-var-scanner: \f488;
$fa-var-scanner-keyboard: \f489;
$fa-var-scanner-touchscreen: \f48a;
$fa-var-schlix: \f3ea;
$fa-var-scribd: \f28a;
$fa-var-scrubber: \f2f8;
$fa-var-search: \f002;
$fa-var-search-minus: \f010;
$fa-var-search-plus: \f00e;
$fa-var-searchengin: \f3eb;
$fa-var-sellcast: \f2da;
$fa-var-sellsy: \f213;
$fa-var-server: \f233;
$fa-var-servicestack: \f3ec;
$fa-var-share: \f064;
$fa-var-share-all: \f367;
$fa-var-share-alt: \f1e0;
$fa-var-share-alt-square: \f1e1;
$fa-var-share-square: \f14d;
$fa-var-shekel-sign: \f20b;
$fa-var-shield: \f132;
$fa-var-shield-alt: \f3ed;
$fa-var-shield-check: \f2f7;
$fa-var-ship: \f21a;
$fa-var-shipping-fast: \f48b;
$fa-var-shipping-timed: \f48c;
$fa-var-shirtsinbulk: \f214;
$fa-var-shopping-bag: \f290;
$fa-var-shopping-basket: \f291;
$fa-var-shopping-cart: \f07a;
$fa-var-shower: \f2cc;
$fa-var-shuttlecock: \f45b;
$fa-var-sign-in: \f090;
$fa-var-sign-in-alt: \f2f6;
$fa-var-sign-language: \f2a7;
$fa-var-sign-out: \f08b;
$fa-var-sign-out-alt: \f2f5;
$fa-var-signal: \f012;
$fa-var-simplybuilt: \f215;
$fa-var-sistrix: \f3ee;
$fa-var-sitemap: \f0e8;
$fa-var-skyatlas: \f216;
$fa-var-skype: \f17e;
$fa-var-slack: \f198;
$fa-var-slack-hash: \f3ef;
$fa-var-sliders-h: \f1de;
$fa-var-sliders-h-square: \f3f0;
$fa-var-sliders-v: \f3f1;
$fa-var-sliders-v-square: \f3f2;
$fa-var-slideshare: \f1e7;
$fa-var-smile: \f118;
$fa-var-smoking: \f48d;
$fa-var-snapchat: \f2ab;
$fa-var-snapchat-ghost: \f2ac;
$fa-var-snapchat-square: \f2ad;
$fa-var-snowflake: \f2dc;
$fa-var-sort: \f0dc;
$fa-var-sort-alpha-down: \f15d;
$fa-var-sort-alpha-up: \f15e;
$fa-var-sort-amount-down: \f160;
$fa-var-sort-amount-up: \f161;
$fa-var-sort-down: \f0dd;
$fa-var-sort-numeric-down: \f162;
$fa-var-sort-numeric-up: \f163;
$fa-var-sort-up: \f0de;
$fa-var-soundcloud: \f1be;
$fa-var-space-shuttle: \f197;
$fa-var-spade: \f2f4;
$fa-var-speakap: \f3f3;
$fa-var-spinner: \f110;
$fa-var-spinner-third: \f3f4;
$fa-var-spotify: \f1bc;
$fa-var-square: \f0c8;
$fa-var-square-full: \f45c;
$fa-var-stack-exchange: \f18d;
$fa-var-stack-overflow: \f16c;
$fa-var-star: \f005;
$fa-var-star-exclamation: \f2f3;
$fa-var-star-half: \f089;
$fa-var-staylinked: \f3f5;
$fa-var-steam: \f1b6;
$fa-var-steam-square: \f1b7;
$fa-var-steam-symbol: \f3f6;
$fa-var-step-backward: \f048;
$fa-var-step-forward: \f051;
$fa-var-stethoscope: \f0f1;
$fa-var-sticker-mule: \f3f7;
$fa-var-sticky-note: \f249;
$fa-var-stop: \f04d;
$fa-var-stop-circle: \f28d;
$fa-var-stopwatch: \f2f2;
$fa-var-strava: \f428;
$fa-var-street-view: \f21d;
$fa-var-strikethrough: \f0cc;
$fa-var-stripe: \f429;
$fa-var-stripe-s: \f42a;
$fa-var-studiovinari: \f3f8;
$fa-var-stumbleupon: \f1a4;
$fa-var-stumbleupon-circle: \f1a3;
$fa-var-subscript: \f12c;
$fa-var-subway: \f239;
$fa-var-suitcase: \f0f2;
$fa-var-sun: \f185;
$fa-var-superpowers: \f2dd;
$fa-var-superscript: \f12b;
$fa-var-supple: \f3f9;
$fa-var-sync: \f021;
$fa-var-sync-alt: \f2f1;
$fa-var-syringe: \f48e;
$fa-var-table: \f0ce;
$fa-var-table-tennis: \f45d;
$fa-var-tablet: \f10a;
$fa-var-tablet-alt: \f3fa;
$fa-var-tablet-android: \f3fb;
$fa-var-tablet-android-alt: \f3fc;
$fa-var-tablet-rugged: \f48f;
$fa-var-tablets: \f490;
$fa-var-tachometer: \f0e4;
$fa-var-tachometer-alt: \f3fd;
$fa-var-tag: \f02b;
$fa-var-tags: \f02c;
$fa-var-tasks: \f0ae;
$fa-var-taxi: \f1ba;
$fa-var-telegram: \f2c6;
$fa-var-telegram-plane: \f3fe;
$fa-var-tencent-weibo: \f1d5;
$fa-var-tennis-ball: \f45e;
$fa-var-terminal: \f120;
$fa-var-text-height: \f034;
$fa-var-text-width: \f035;
$fa-var-th: \f00a;
$fa-var-th-large: \f009;
$fa-var-th-list: \f00b;
$fa-var-themeisle: \f2b2;
$fa-var-thermometer: \f491;
$fa-var-thermometer-empty: \f2cb;
$fa-var-thermometer-full: \f2c7;
$fa-var-thermometer-half: \f2c9;
$fa-var-thermometer-quarter: \f2ca;
$fa-var-thermometer-three-quarters: \f2c8;
$fa-var-thumbs-down: \f165;
$fa-var-thumbs-up: \f164;
$fa-var-thumbtack: \f08d;
$fa-var-ticket: \f145;
$fa-var-ticket-alt: \f3ff;
$fa-var-times: \f00d;
$fa-var-times-circle: \f057;
$fa-var-times-hexagon: \f2ee;
$fa-var-times-octagon: \f2f0;
$fa-var-times-square: \f2d3;
$fa-var-tint: \f043;
$fa-var-toggle-off: \f204;
$fa-var-toggle-on: \f205;
$fa-var-trademark: \f25c;
$fa-var-train: \f238;
$fa-var-transgender: \f224;
$fa-var-transgender-alt: \f225;
$fa-var-trash: \f1f8;
$fa-var-trash-alt: \f2ed;
$fa-var-tree: \f1bb;
$fa-var-tree-alt: \f400;
$fa-var-trello: \f181;
$fa-var-triangle: \f2ec;
$fa-var-tripadvisor: \f262;
$fa-var-trophy: \f091;
$fa-var-trophy-alt: \f2eb;
$fa-var-truck: \f0d1;
$fa-var-tty: \f1e4;
$fa-var-tumblr: \f173;
$fa-var-tumblr-square: \f174;
$fa-var-tv: \f26c;
$fa-var-tv-retro: \f401;
$fa-var-twitch: \f1e8;
$fa-var-twitter: \f099;
$fa-var-twitter-square: \f081;
$fa-var-typo3: \f42b;
$fa-var-uber: \f402;
$fa-var-uikit: \f403;
$fa-var-umbrella: \f0e9;
$fa-var-underline: \f0cd;
$fa-var-undo: \f0e2;
$fa-var-undo-alt: \f2ea;
$fa-var-uniregistry: \f404;
$fa-var-universal-access: \f29a;
$fa-var-university: \f19c;
$fa-var-unlink: \f127;
$fa-var-unlock: \f09c;
$fa-var-unlock-alt: \f13e;
$fa-var-untappd: \f405;
$fa-var-upload: \f093;
$fa-var-usb: \f287;
$fa-var-usd-circle: \f2e8;
$fa-var-usd-square: \f2e9;
$fa-var-user: \f007;
$fa-var-user-alt: \f406;
$fa-var-user-circle: \f2bd;
$fa-var-user-md: \f0f0;
$fa-var-user-plus: \f234;
$fa-var-user-secret: \f21b;
$fa-var-user-times: \f235;
$fa-var-users: \f0c0;
$fa-var-ussunnah: \f407;
$fa-var-utensil-fork: \f2e3;
$fa-var-utensil-knife: \f2e4;
$fa-var-utensil-spoon: \f2e5;
$fa-var-utensils: \f2e7;
$fa-var-utensils-alt: \f2e6;
$fa-var-vaadin: \f408;
$fa-var-venus: \f221;
$fa-var-venus-double: \f226;
$fa-var-venus-mars: \f228;
$fa-var-viacoin: \f237;
$fa-var-viadeo: \f2a9;
$fa-var-viadeo-square: \f2aa;
$fa-var-vial: \f492;
$fa-var-vials: \f493;
$fa-var-viber: \f409;
$fa-var-video: \f03d;
$fa-var-vimeo: \f40a;
$fa-var-vimeo-square: \f194;
$fa-var-vimeo-v: \f27d;
$fa-var-vine: \f1ca;
$fa-var-vk: \f189;
$fa-var-vnv: \f40b;
$fa-var-volleyball-ball: \f45f;
$fa-var-volume-down: \f027;
$fa-var-volume-mute: \f2e2;
$fa-var-volume-off: \f026;
$fa-var-volume-up: \f028;
$fa-var-vuejs: \f41f;
$fa-var-warehouse: \f494;
$fa-var-warehouse-alt: \f495;
$fa-var-watch: \f2e1;
$fa-var-weibo: \f18a;
$fa-var-weight: \f496;
$fa-var-weixin: \f1d7;
$fa-var-whatsapp: \f232;
$fa-var-whatsapp-square: \f40c;
$fa-var-wheelchair: \f193;
$fa-var-whistle: \f460;
$fa-var-whmcs: \f40d;
$fa-var-wifi: \f1eb;
$fa-var-wikipedia-w: \f266;
$fa-var-window: \f40e;
$fa-var-window-alt: \f40f;
$fa-var-window-close: \f410;
$fa-var-window-maximize: \f2d0;
$fa-var-window-minimize: \f2d1;
$fa-var-window-restore: \f2d2;
$fa-var-windows: \f17a;
$fa-var-won-sign: \f159;
$fa-var-wordpress: \f19a;
$fa-var-wordpress-simple: \f411;
$fa-var-wpbeginner: \f297;
$fa-var-wpexplorer: \f2de;
$fa-var-wpforms: \f298;
$fa-var-wrench: \f0ad;
$fa-var-x-ray: \f497;
$fa-var-xbox: \f412;
$fa-var-xing: \f168;
$fa-var-xing-square: \f169;
$fa-var-y-combinator: \f23b;
$fa-var-yahoo: \f19e;
$fa-var-yandex: \f413;
$fa-var-yandex-international: \f414;
$fa-var-yelp: \f1e9;
$fa-var-yen-sign: \f157;
$fa-var-yoast: \f2b1;
$fa-var-youtube: \f167;
$fa-var-youtube-square: \f431;
